import React from 'react';
import { Link } from 'gatsby';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../header/Header';
import * as styles from './NotFound.module.scss';
import image1x from '../../images/404.png';
import image2x from '../../images/404@2x.png';

const NotFound = () => {
	return (
		<section className={styles.section}>
			<Header logoColour="yellow" />
			<div className={styles.content}>
				<Container>
					<Row>
						<Col>
							<figure>
								<img
									className="img-fluid"
									alt="Graphic of somebody placing an image"
									width={360}
									height={392}
									srcSet={`${image1x} 1x, ${image2x} 2x`}
									src={image1x}
								/>
							</figure>
							<h1>404</h1>
							<p className="mb-5">Page not found</p>
							<Link
								className="btn btn-primary"
								to="/"
								aria-label="Return to the homepage"
							>Return to homepage</Link>
						</Col>
					</Row>
				</Container>
			</div>
		</section>
	);
};

export default NotFound;
