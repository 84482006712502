import React from 'react';
import NotFound from '../components/404/NotFound';

const Page404 = () => {
	return (
		<NotFound />
	)
}

export default Page404;
